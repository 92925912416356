
.App{
  display: flex;
  height: 100%;
}
#app-sidebar{
  width: 15%;
}
#app-pages{
  width: 85%;
  overflow-x: scroll;
  padding-inline: 20px;
}
.main-container{
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 100%;
  align-items: center;
}
