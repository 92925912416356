#sidebar{
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #3b13da;
    padding-top: 25px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

}
#sidebar a{
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: aliceblue;
  font-family: "Roboto", sans-serif;
}
#sidebar a:hover{
    background-color: aliceblue;
    color: #3b13da;
}