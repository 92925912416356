@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
}
.hide {
  display: none !important;
}
.Form {
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: flex-start; */
  padding: 20px;
  border: #254fc4 2px solid;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  width: 60%;
  max-height: 95vh;
  overflow-y: scroll;
}

.FormWrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.364);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

h1 {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
}
table {
  width: 100%;
  table-layout: fixed;
}
.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}
.tbl-content {
  height: 300px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.3);
}
td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  word-wrap: break-word;
}

/* demo styles */

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
table {
  background: -webkit-linear-gradient(left, #254fc4, rgb(70, 8, 131));
  background: linear-gradient(to right, #254fc4, rgb(70, 8, 131));
  font-family: "Roboto", sans-serif;
}

/* for custom scrollbar for webkit browser*/


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
label{
  font-family: "Roboto", sans-serif;
    font-weight: 600;
}
input[type="submit"]{
  font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: #254fc4;
    color: aliceblue;
    padding:7px 15px;
    border-radius: 10px;
}
input[type="text"], input[type="textarea"], input[type="number"]{
  font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: aliceblue;
    color: black;
    padding:7px 15px;
    border: #254fc4 2px solid;
    border-radius: 5px;
    width: 100%;
}
table{
  border-radius: 15px;
}
.wrap-submit{
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  width: 100%;
}
 table + div,.addBtn{
  margin-top:5px;
  border-radius: 10px;
  width: 100%;
  /* position: relative;
  left: 50%;
  transform: translate(-50%); */
}
.closeFormBtn{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
h4{
  font-size: 20px;
}